import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-select/dist/css/bootstrap-select.css';

import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/solid.css';

import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
import 'datatables.net-responsive-dt/css/responsive.dataTables.css';
import 'datatables.net-select-dt/css/select.dataTables.css';
import 'swagger-ui-dist/swagger-ui.css';

import '../css/loading.css';
import '../css/portal.css';

/* Expose jQuery and its alias $ into the global scope. Some libraries like Bootstrap and datatables
 * install jQuery extension functions, but they do so only if the global $ object exists. */
import $ from 'expose-loader?exposes=$,jQuery!jquery';
import 'jquery-validation';
import 'jquery-validation/dist/additional-methods';

/* Expose bootstrap into the global scope (bootstrap-select depends on this) */
import * as bootstrap from 'expose-loader?exposes=bootstrap!bootstrap';
window.bootstrap = bootstrap;
import 'bootstrap-select';

import 'datatables.net-dt';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-select-dt';

import { getCognitoAuthToken } from './js/portal/auth';
import { CONF } from './js/portal/environment';
import { updateTableKeyBinding } from './js/portal/main';
import { configureSidebar, startNavigationProcess } from './js/portal/sidebar';
import { configureTempPrivilegeTopNav } from './js/portal/temporary_privilege_escalation';

import '../js/load_component';
import './js/portal/datatables_custom_plugins';

import App from './app';

import changelogHtml from './_changelog.html';
import { load_micro_service_changelogs } from './js/portal/micro_changelogs';
import { Modal } from 'bootstrap';

const startApp = app => {
  $('body').append(app);

  // Set the current window hash and search parameters directly after the page is loaded. This, together with the
  // function sidebar.js/startNavigationProcess and sidebar.js/setSearchParamsUrl makes it possible to have deep
  // links, even if there are already search parameters in the url.
  // if (window.location.hash) sessionStorage.lastHash = window.location.hash;
  if (window.location.search) sessionStorage.lastSearch = window.location.search;

  // Forces to reload the page after clicking the same sidebar item twice, for example #itsm is currently loaded
  // and you click ITSM Event Bridge in the sidebar once more will reload the page again. Otherwise it would just
  // ignore the click as the hash value in the URL has not changed.
  $('.sidebar').on('click', '.sidebar-item:not(.sidebar-menu) > a', function () {
    if ($(this).attr('href') === window.location.hash) {
      $(window).trigger('hashchange');
    }
  });

  if (window.location.hash || window.location.pathname === '/' || window.location.pathname === '/aws/index.html') {
    // in case no lastHash
    if (!sessionStorage.lastHash || window.location.pathname === '/aws/index.html') {
      if (window.location.hash) {
        sessionStorage.lastHash = window.location.hash;
      } else {
        sessionStorage.lastHash = '#dashboard';
      }
    }

    // Start navigation process
    startNavigationProcess(sessionStorage.lastHash);

    // Check the session validity for every hashchange in the URL
    $(window).on('hashchange', () => {
      startNavigationProcess(window.location.hash);
    });
  } else {
    const pathnames = window.location.pathname.split('/');
    startNavigationProcess(`#${pathnames[1]}`);

    // checkout session is still valid
    // since micro-frontend will bypass most of the auth functions
    // we need an extra check for micro-frontend
    getCognitoAuthToken();
  }

  // Note: process.env.PORTAL_VERSION gets replaced at build time with the actual version from package.json
  $('#portal-version').text(process.env.PORTAL_VERSION);
  $('#version-bottom').text(CONF.backendEnv);

  // Check if the session is still valid after the CognitoIdentityServiceProvider information in the localStorage
  // have changed. If the Cognito session has expired the user can reload the page or will be forwarded to the login
  // page to authenticate again.
  $(window).on('storage', async storageEvent => {
    if (storageEvent.originalEvent.key && storageEvent.originalEvent.key.includes('CognitoIdentityServiceProvider')) {
      getCognitoAuthToken();
    }
  });

  // Used for table navigation (left, right, pos1) and to show the portal config (strg+i)
  $(window).on('keydown', function (e) {
    updateTableKeyBinding(e);
  });

  $('#changelog').html(changelogHtml);

  // load micro service changelogs from urls
  load_micro_service_changelogs();

  if ($('#sharedModal').length) {
    const shared_modal = new Modal('#sharedModal'); // NOSONAR
    new Modal('#temporaryRoleModal'); // NOSONAR

    if (shared_modal) {
      $(shared_modal._element).on('hidden.bs.modal', function () {
        shared_modal._dialog.removeAttribute('data-type');
        const modal_footer = shared_modal._dialog.querySelector('.modal-footer');
        if (modal_footer)
          modal_footer.innerHTML =
            '<button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>';
      });
    }
  }
};

const createApp = () => {
  let promises = [];
  let app = App();

  promises.push(configureSidebar($('.sidebar-menu', app).get(0)));
  promises.push(configureTempPrivilegeTopNav($("#userDropdownItemTempPrivilegeEscalation", app).get(0)));

  return new Promise((resolve, reject) => {
    Promise.all(promises).then(() => {
      resolve(app);
    });
  });
};

$(() => {
  createApp().then(app => startApp(app));
});
